/* eslint-disable no-param-reassign */
import Rails from 'rails-ujs';
import { approveInAppDialogContent } from './transfer_requests/approve_base';

const confirmed = ($element, result) => {
  if (result.value) {
    // User clicked confirm button
    const confirm = $element.data('confirm');
    const confirmPin = $element.data('confirm-pin');
    const confirm2fa = $element.data('confirm-2fa');
    const confirmApproveInApp = $element.data('approve-in-app');
    delete $element.removeAttr('data-confirm').removeData('confirm').get(0).dataset.confirm;
    delete $element.removeAttr('data-confirm-pin').removeData('confirm-pin').get(0).dataset.confirmPin;
    delete $element.removeAttr('data-confirm-2fa').removeData('confirm-2fa').get(0).dataset.confirm2fa;
    delete $element.removeAttr('data-approve-in-app').removeData('approve-in-app').get(0).dataset.confirmApproveInApp;
    $element[0].click();
    $element.attr('data-confirm', confirm);
    $element.attr('data-confirm-pin', confirmPin);
    $element.attr('data-confirm-2fa', confirm2fa);
    $element.attr('data-approve-in-app', confirmApproveInApp);
  }
};

const afterDialogConfirmation = ($element, operation, attribute, result, extraParams = {}) => {
  if (!result.value) return;

  // eslint-disable-next-line no-param-reassign
  extraParams[attribute] = result.value;

  if ($element.is('a') || $element.data('method')) {
    if ($element.data('remote') && $element.data('remote') !== 'false') {
      const params = new URLSearchParams($element.data('params'));

      Object.entries(extraParams).forEach(([key, value]) => {
        params.append(key, value);
      });

      $element.attr('data-params', params.toString());
    } else {
      console.warn('Não utilizar envio de PIN via query string para que o mesmo seja filtrado corretamente.');

      const href = $element.attr('href');
      const path = href.split('?')[0];
      const params = new URLSearchParams(decodeURI(href.split('?')[1]));

      Object.entries(extraParams).forEach(([key, value]) => {
        params.append(key, value);
      });

      $element.attr('href', `${path}?${params.toString()}`);
    }
    confirmed($element, result);
  } else if ($element.prop('tagName') === 'FORM') {
    Object.entries(extraParams).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      $element.append(input);
    });

    $element.removeAttr(`data-confirm-${operation}`);
    if ($element.data('remote') && $element.data('remote') !== 'false') {
      Rails.fire($element[0], 'submit');
    } else {
      $element.trigger('submit.rails');
    }
    $element.attr(`data-confirm-${operation}`, true);
  }
};

const showPinDialog = ($element) => {
  Swal.fire({
    title: 'Digite o seu PIN',
    input: 'password',
    inputPlaceholder: 'Digite o seu PIN',
    confirmButtonText: 'Confirmar',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    customClass: {
      actions: 'flex-row-reverse',
    },
    showLoaderOnConfirm: true,
    footer: null,
    allowOutsideClick: () => !Swal.isLoading(),
    inputAttributes: {
      id: 'swal2-pin-input',
      maxlength: 4,
      autocapitalize: 'off',
      autocorrect: 'off',
    },
    inputValidator: (value) => {
      if (value.length !== 4) {
        return 'O PIN deve ter 4 dígitos';
      }
      return null;
    },
    onBeforeOpen: () => {
      Swal.getInput().onkeypress = event => Number.isInteger(parseInt(event.key, 10));
    },
    preConfirm: pin => fetch('/users/pin/validate', {
      method: 'PATCH',
      redirect: 'manual',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pin: `${pin}`,
        action_param: `${$element.data('action-param')}`,
      }),
    })
      .then((response) => {
        if (response.ok) return pin;

        Swal.showValidationMessage('Erro');

        if (response.status === 401) {
          response.json().then((data) => {
            Swal.showValidationMessage(data.message);
            if (data.present === false) {
              Turbolinks.visit('/');
            } else {
              const message = `${data.remaining_attempts} tentativas restantes antes do seu PIN ser bloqueado`;
              Swal.getFooter().innerHTML = message;
              Swal.getFooter().style.display = 'flex';
            }
          });
        } else if (response.status === 0) {
          Swal.showValidationMessage('Você está deslogado');
          Turbolinks.visit(window.location);
        } else {
          throw new Error(
            `Unexpected PIN response. Status: ${response.status}, message: ${response.statusText}`,
          );
        }

        return pin;
      })
      .catch((error) => {
        Swal.showValidationMessage('Erro inesperado');
        throw error;
      }),
  }).then(result => afterDialogConfirmation($element, 'pin', 'pin', result));
};

const twoFactorDialog = ($element) => {
  const invalidCodeUrl = $element.data('invalid-2fa-code-callback-url') || '';
  const extraParams = { invalid_2fa_code_callback_url: invalidCodeUrl };

  Swal.fire({
    title: 'Confirmação de segurança',
    text: 'Para continuar, digite o código de segurança da autenticação de dois fatores.',
    input: 'text',
    inputPlaceholder: 'Código de segurança',
    icon: 'warning',
    confirmButtonText: 'Confirmar',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    customClass: {
      actions: 'flex-row-reverse',
    },
    showLoaderOnConfirm: true,
    footer: null,
    allowOutsideClick: () => !Swal.isLoading(),
    inputAttributes: {
      id: 'swal2-2fa-input',
      maxlength: 6,
      autocapitalize: 'off',
      autocorrect: 'off',
    },
    inputValidator: (value) => {
      if (value.length !== 6) {
        return 'O 2FA deve ter 6 dígitos';
      }
      return null;
    },
    onBeforeOpen: () => {
      Swal.getInput().onkeypress = event => Number.isInteger(parseInt(event.key, 10));
    },
  }).then(result => afterDialogConfirmation($element, '2fa', 'otp_attempt', result, extraParams));
};

const requiresPinConfirmation = ($element) => {
  const confirmPin = $element.data('confirm-pin');
  return (
    confirmPin
    && confirmPin !== 'false'
    && (localStorage.getItem('force_pin_authentication') === 'true')
  );
};

const requires2faConfirmation = ($element) => {
  const confirm2fa = $element.data('confirm-2fa');
  return (
    confirm2fa
    && confirm2fa !== 'false'
  );
};


const show2faDialog = ($element) => {
  const sendTwoFactorCodeUrl = $element.data('confirm-2fa-code-url') || '/users/two_factor_authentication/send_code';

  $.ajax({
    url: sendTwoFactorCodeUrl,
    type: 'POST',
    dataType: 'json',
    success: (data) => {
      if (data.response === true) {
        twoFactorDialog($element);
      } else {
        Swal.fire({
          title: 'Erro',
          text: 'Erro ao enviar código autenticador, tente novamente mais tarde.',
          icon: 'error',
        });
      }
    },
    error: () => {
      Swal.fire({
        title: 'Erro',
        text: 'Erro inesperado, tente novamente mais tarde.',
        icon: 'error',
      });
    },
  });
};

const handleConfirmationResult = ($element, result) => {
  if (!result.value) return;
  const requiresPin = requiresPinConfirmation($element);
  const requires2fa = requires2faConfirmation($element);

  if (requires2fa) {
    show2faDialog($element);
    return;
  }

  if (requiresPin) {
    showPinDialog($element);
    return;
  }
  confirmed($element, result);
};

const composeData = ($element, data) => $element.data(data) || $element.parent().data(data);

const showConfirmationDialog = ($element) => {
  const message = $element.data('confirm');
  const text = $element.data('text');
  const html = composeData($element, 'html');
  const confirmButton = composeData($element, 'confirm-button');
  const customClass = composeData($element, 'custom-class');
  const showCancelButtonValue = $element.data('show-cancel-button');

  Swal.fire({
    title: message || 'Você tem certeza?',
    text: text || '',
    html,
    icon: 'warning',
    showCancelButton: showCancelButtonValue !== undefined ? showCancelButtonValue : true,
    confirmButtonText: confirmButton || 'Sim',
    cancelButtonText: 'Cancelar',
    customClass: {
      actions: 'flex-row-reverse',
      popup: 'swal-confirm-modal',
      title: 'title',
      content: 'content',
      container: customClass || '',
    },
  }).then(result => handleConfirmationResult($element, result));
};

const showApproveInAppDialog = ($element) => {
  Swal.fire(approveInAppDialogContent).then(result => afterDialogConfirmation($element, undefined, undefined, result));
};

const allowAction = (element) => {
  const $element = $(element);

  if ($element.data('approve-in-app') || $element.parent().data('approve-in-app')) {
    showApproveInAppDialog($element);
    return false;
  }

  if ($element.data('confirm')) {
    showConfirmationDialog($element);
    return false;
  }
  if (requires2faConfirmation($element)) {
    show2faDialog($element);
    return false;
  }

  if (requiresPinConfirmation($element)) {
    showPinDialog($element);
    return false;
  }

  return true;
};

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
}

Rails.delegate(
  document,
  `a[data-confirm], input[data-confirm], a[data-confirm-pin], input[data-confirm-pin],
  button[data-confirm], a[data-confirm-2fa], input[data-confirm-2fa]`,
  'click',
  handleConfirm,
);
Rails.delegate(document, 'form[data-confirm-pin]', 'submit', handleConfirm);
Rails.delegate(document, 'form[data-confirm-2fa]', 'submit', handleConfirm);
